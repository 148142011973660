/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

mat.$theme-ignore-duplication-warnings: true

;

@include mat.core();

$dark-theme: mat.define-theme((color: (theme-type: dark, primary: mat.$azure-palette), density: (scale: -1)));
$light-theme: mat.define-theme((color: (theme-type: light, primary: mat.$azure-palette), density: (scale: -1)));

html {

  @media (prefers-color-scheme: light) {
    @include mat.all-component-colors($light-theme);
    background-color: #ffffff;
  }

  .light, [data-theme="light"] {
    @include mat.all-component-colors($light-theme);
    background-color: #ffffff;
  }

  @media (prefers-color-scheme: dark) {
    @include mat.all-component-colors($dark-theme);
    background-color: #191c1c;
  }

  .dark, [data-theme="dark"] {
    @include mat.all-component-colors($dark-theme);
    background-color: #191c1c;
  }

}

@tailwind base;
@tailwind components;
@tailwind utilities;

// This goes into global, non-encapsulated styles

html:has(.drawer-toggle:checked) {
  overflow-y: hidden;
  scrollbar-gutter: auto;
}

* {
  scrollbar-color: initial !important;
}

*:hover {
  scrollbar-color: initial !important;
}

:root {
  --sb-size: 10px;

  @media (prefers-color-scheme: light) {
    --sb-track-color: #c9d6fc;
    --sb-thumb-color: #002f65;
  }

  .light, [data-theme="light"] {
    --sb-track-color: #c9d6fc;
    --sb-thumb-color: #002f65;
  }

  @media (prefers-color-scheme: dark) {
    --sb-track-color: rgb(0, 39, 75);
    --sb-thumb-color: rgb(124 171 255);
  }

  .dark, [data-theme="dark"] {
    --sb-track-color: rgb(0, 39, 75);
    --sb-thumb-color: rgb(124 171 255);
  }

}

*::-webkit-scrollbar {
  width: var(--sb-size);
  height: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.custom-notification-container {

  .mdc-snackbar__surface.mat-mdc-snackbar-surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }

}
